<template>
    <v-card
        :variant="tonal"
        class="mx-auto d-flex flex-column opacity-90 bg-red"
        :class="{
            'width-600': $vuetify.display.mdAndUp,
            'w-100': $vuetify.display.smAndDown,
            'height-400': $vuetify.display.mdAndUp,
            // 'height-185': $vuetify.display.smAndDown,
        }">
        <v-card-title class="text-subtitle-1 text-md-h3">
            <div left class="courgette-regular">
                {{ $t("landingPage.component1.card.title") }}
            </div>
        </v-card-title>
        <v-card-text class="text-body-1 text-md-h4">
            <div class="playfair-display">
                {{ $t("landingPage.component1.card.text") }}
            </div>
            <v-spacer></v-spacer>
        </v-card-text>

        <template v-slot:actions>
            <v-spacer></v-spacer>
            <contact-form-card></contact-form-card>
        </template>
    </v-card>
</template>
<script>
import { defineComponent } from "vue";
import ContactFormCard from "./ContactFormCard.vue";

// Components
export default defineComponent({
    name: "Section1Card",

    components: { ContactFormCard },
    computed: {
        size() {
            if (this.$vuetify.display.mdAndUp) {
                return "large";
            } else {
                return "medium";
            }
        },
    },
});
</script>
<style scoped>
.width-600 {
    width: 600px !important;
}
.width-250 {
    width: 250px;
}
.height-400 {
    height: 400px;
}
.height-185 {
    height: 185px;
}
</style>
