<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                variant="outlined"
                class="justify-right"
                :class="{
                    'mt-0': $vuetify.display.mdAndUp,
                    'mt-n6': $vuetify.display.smAndDown,
                }"
                rounded="lg"
                elevation="4"
                :size="size"
                v-bind="activatorProps"
                @click="formNotSent = true"
                block>
                <template v-slot:append>
                    <v-icon :size="size" icon="mdi-email" class="ml-1"></v-icon>
                </template>
                <template v-slot:default>
                    <div
                        class="playfair-display text-uppercase"
                        :class="{
                            'text-h5': $vuetify.display.mdAndUp,
                            'text-body-2': $vuetify.display.smAndDown,
                        }">
                        {{ $t("landingPage.component1.card.button") }}
                    </div>
                </template>
            </v-btn>
        </template>

        <v-card
            prepend-icon="mdi-email"
            title="Inqiure about the price and available dates."
            width="100%"
            class="justify-center">
            <template v-slot:title>
                <p class="text-wrap">
                    {{ $t("landingPage.component1.contactForm.title") }}
                </p>
            </template>
            <template v-slot:subtitle>
                <p class="text-wrap">
                    {{ $t("landingPage.component1.contactForm.subtitle") }}
                </p>
            </template>
            <v-card-text v-if="formNotSent">
                <v-row dense>
                    <v-col cols="12" md="6" sm="6">
                        <v-text-field
                            :label="
                                $t(
                                    'landingPage.component1.contactForm.firstName'
                                )
                            "
                            v-model="firstName"
                            required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                        <v-text-field
                            :label="
                                $t(
                                    'landingPage.component1.contactForm.lastName'
                                )
                            "
                            v-model="lastName"
                            required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                        <v-text-field
                            :label="
                                $t('landingPage.component1.contactForm.email')
                            "
                            v-model="email"
                            required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                        <v-select
                            :items="['1', '2', '3', '4', '5', '6', '7', '8']"
                            :label="
                                $t(
                                    'landingPage.component1.contactForm.nOfPeople'
                                )
                            "
                            v-model="nOfPeople"
                            required></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                        <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-text-field
                                    v-bind="props"
                                    readonly
                                    required
                                    v-model="formattedDateFrom"
                                    :label="
                                        $t(
                                            'landingPage.component1.contactForm.startingDate'
                                        )
                                    "></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateFrom"
                                color="green"
                                hide-header></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-text-field
                                    v-bind="props"
                                    readonly
                                    required
                                    v-model="formattedDateTo"
                                    :label="
                                        $t(
                                            'landingPage.component1.contactForm.endingDate'
                                        )
                                    "></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateTo"
                                color="green"
                                hide-header></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-textarea
                            :label="
                                $t(
                                    'landingPage.component1.contactForm.additionalInfo'
                                )
                            "
                            v-model="additionalInfo"></v-textarea>
                    </v-col>
                </v-row>

                <small class="text-caption text-medium-emphasis">
                    {{ $t("landingPage.component1.contactForm.indicates") }}
                </small>
            </v-card-text>
            <v-card-text v-else-if="formSuccess">
                <v-row dense>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-icon
                            icon="mdi-check-circle-outline"
                            size="x-large"
                            color="green"></v-icon>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" class="d-flex justify-center">
                        <p>
                            {{
                                $t("landingPage.component1.contactForm.success")
                            }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else-if="formError">
                <v-row dense>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-icon
                            icon="mdi-close-circle-outline"
                            size="x-large"
                            color="red"></v-icon>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" class="d-flex justify-center">
                        <p>
                            {{
                                $t("landingPage.component1.contactForm.problem")
                            }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="spinnerVisible">
                <v-row dense>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate></v-progress-circular>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    :text="$t('landingPage.component1.contactForm.close')"
                    variant="plain"
                    @click="
                        dialog = false;
                        formNotSent = true;
                        formError = false;
                        formSuccess = false;
                        spinnerVisible = false;
                    "
                    v-if="!spinnerVisible"></v-btn>

                <v-btn
                    color="green"
                    :text="$t('landingPage.component1.contactForm.send')"
                    variant="tonal"
                    :disabled="!isFormValid"
                    @click="sendEmail()"
                    v-if="formNotSent"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import emailjs from "@emailjs/browser";

// Components
export default defineComponent({
    name: "ContactFormCard",

    components: {},
    data: () => ({
        dialog: false,
        dateFromPicker: false,
        dateFrom: new Date(),
        dateTo: new Date(),
        firstName: null,
        lastName: null,
        email: null,
        nOfPeople: 1,
        additionalInfo: null,
        formNotSent: true,
        formError: false,
        formSuccess: false,
        spinnerVisible: false,
    }),
    computed: {
        size() {
            if (this.$vuetify.display.mdAndUp) {
                return "large";
            } else {
                return "small";
            }
        },
        formattedDateFrom() {
            return this.dateFrom.toLocaleDateString("en-GB");
        },
        formattedDateTo() {
            return this.dateTo.toLocaleDateString("en-GB");
        },
        isFormValid() {
            return (
                this.firstName &&
                this.lastName &&
                this.email &&
                this.nOfPeople &&
                this.dateFrom &&
                this.dateTo
            );
        },
    },
    methods: {
        sendEmail() {
            this.spinnerVisible = true;
            this.formNotSent = false;
            emailjs
                .send(
                    "service_p0g33w7",
                    "template_mqgc6h9",
                    {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        nOfPeople: this.nOfPeople,
                        dateFrom: this.formattedDateFrom,
                        dateTo: this.formattedDateTo,
                        additionalInfo: this.additionalInfo,
                    },
                    { publicKey: "CWQ3LbAynNgdN_pm4" }
                )
                .then(
                    (result) => {
                        this.spinnerVisible = false;
                        this.formSuccess = true;
                        console.log(
                            "You have successfully submitted your message",
                            result.text
                        );
                    },
                    (error) => {
                        this.spinnerVisible = false;
                        this.formError = true;
                        console.log(
                            "This form failed to submit, please kindly check your internet connection",
                            error.text
                        );
                    }
                );

            // Reset form field
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.nOfPeople = null;
            this.dateFrom = new Date();
            this.dateTo = new Date();
            this.additionalInfo = null;
        },
    },
});
</script>
<style scoped>
.width-600 {
    width: 600px !important;
}
.width-300 {
    width: 300px;
}
.height-400 {
    height: 400px;
}
.height-200 {
    height: 200px;
}
</style>
